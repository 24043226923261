import useSWR from 'swr';
import useSWRMutation from 'swr/mutation';

import useEntityServiceClient from './useEntityServiceClient';

export function useOrganizationData() {
  const entityServiceClient = useEntityServiceClient();

  const {data} = useSWR(['getCurrentOrganization'], async () => {
    const {data} = await entityServiceClient.findOne('configs', {
      fields: ['name', 'logoUrl'],
    });

    return data;
  });

  const updateOrganizationData = useSWRMutation(
    ['getCurrentOrganization'],
    async (
      _key,
      {
        arg,
      }: {
        arg: {
          name: string;
          logoUrl: string;
        };
      }
    ) => {
      await entityServiceClient.update('configs', data.id, {
        data: {
          name: arg.name,
          logoUrl: arg.logoUrl,
        },
      });
    }
  );

  return {
    organizationData: data,
    updateOrganizationData: updateOrganizationData.trigger,
  };
}
