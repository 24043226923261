'use client';
import Link from 'next/link';

import Avatar from '@src/components/core/display/Avatar';
import TetraDots from '@src/components/TetraDots';
import {useOrganizationData} from '@src/hooks/useOrganizationData';

const OrganizationLogo = () => {
  const {organizationData} = useOrganizationData();
  const logoUrl = organizationData?.attributes?.logoUrl;

  return (
    <Link href="/">
      {logoUrl ? (
        <Avatar height={32} src={logoUrl} variant="rounded" width={32} />
      ) : (
        <TetraDots />
      )}
    </Link>
  );
};

export default OrganizationLogo;
