'use client';

import {useState} from 'react';

import {Box} from '@mui/material';

import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import {
  Gear,
  PuzzlePiece,
  Question,
  SignOut,
  UserCircleGear,
  UserPlus,
  UserSwitch,
} from '@phosphor-icons/react';
import {useRouter} from 'next/navigation';
import {usePostHog} from 'posthog-js/react';

import InviteUserModal from '../../components/layouts/core/app/InviteUserModal';
import UserOrganizationsDialog from '../../components/layouts/core/app/UserOrganizationsDialog';
import Menu from '@components/core/navigation/Menu';

import Avatar from '@src/components/core/display/Avatar';
import usePermission from '@src/hooks/usePermission';
import {useSubdomain} from '@src/hooks/useSubdomain';
import useUser from '@src/hooks/useUser';

const AccountAvatarMenu = () => {
  const [anchorEl, setAnchorEl] = useState<any | HTMLElement>();
  const open = Boolean(anchorEl);

  const router = useRouter();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl('');
  };

  const [showOrganizationModal, setShowOrganizationModal] = useState(false);
  const [showInvitationModal, setShowInvitationModal] = useState(false);

  const posthog = usePostHog();

  const canManageUsers = usePermission('manage', 'users');
  const canInviteUsers = usePermission('invite', 'all');

  const subdomain = useSubdomain();
  const {user} = useUser();

  return (
    <Box
      sx={{
        '& .mainBtn': {
          position: 'relative',
          zIndex: 2,
          width: '32px',
          height: '32px',
          overflow: 'hidden',
          borderRadius: '8px',
        },
      }}
    >
      <IconButton
        color="secondary"
        sx={{
          p: 0,
          '&:hover': {
            background: 'transparent',
          },
        }}
        onClick={handleClick}
      >
        <Avatar height={24} src={user?.picture} variant="circular" width={24} />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        id="account-menu"
        items={[
          {
            isHidden: false,
            disabled: true,
            itemChildren: (
              <Grid columnSpacing={1} container>
                <Grid item>
                  <Avatar
                    height={40}
                    src={user?.picture}
                    sx={{borderRadius: '8px'}}
                    width={40}
                  />
                </Grid>
                <Grid item>
                  <Typography variant="body3">{user?.email}</Typography>
                  <Typography fontWeight={600} variant="body2">
                    {user?.org_name}
                  </Typography>
                </Grid>
              </Grid>
            ),
          },
          {
            divider: true,
          },
          {
            icon: <UserCircleGear />,
            label: 'User Settings',
            onClick: () => {
              router.push('/my');
            },
          },
          {
            icon: <PuzzlePiece />,
            isHidden: true,
            label: 'Integrations',
            onClick: () => {
              router.push('/my/integrations');
            },
          },
          {
            icon: <Gear />,
            isHidden: !canManageUsers,
            label: 'Organization Settings',
            onClick: () => {
              router.push('/settings/users');
            },
          },
          {
            isHidden: !canManageUsers,
            divider: true,
          },
          {
            icon: <UserPlus />,
            isHidden: !canInviteUsers,
            label: 'Invite User',
            onClick: () => {
              setShowInvitationModal(true);
            },
          },
          {
            icon: <UserSwitch />,
            label: 'Switch Organization',
            onClick: () => {
              setShowOrganizationModal(true);
            },
          },
          {
            icon: <Question />,
            label: 'Help',
            onClick: () => {
              window.open('https://intercom.help/tetra/en/', '_blank');
              handleClose();
            },
          },
          {
            icon: <SignOut />,
            label: 'Sign Out',
            onClick: async () => {
              posthog.reset();

              const url = new URL(`/api/workos/logout/`, process.env.WEB_BASE_URL);
              if (subdomain) {
                url.host = `${subdomain}.${url.host}`;
              }

              const response = await fetch(url);
              const redirectTo = await response.json();
              router.push(redirectTo);
            },
          },
        ]}
        open={open}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        onClose={handleClose}
      />
      {showOrganizationModal && (
        <UserOrganizationsDialog
          open={showOrganizationModal}
          userId={user.sub}
          onClose={() => {
            setShowOrganizationModal(false);
          }}
        />
      )}

      {showInvitationModal && (
        <InviteUserModal
          open={showInvitationModal}
          onClose={() => {
            setShowInvitationModal(false);
          }}
        />
      )}
    </Box>
  );
};

export default AccountAvatarMenu;
