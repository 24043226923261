'use client';

import {useMemo} from 'react';

import {Tab, Tabs} from '@mui/material';
import Link from 'next/link';
import {useParams, usePathname, useSearchParams} from 'next/navigation';

import usePermission from '@src/hooks/usePermission';
import useProjectLayoutData from '@src/hooks/useProjectLayoutData';
import useSessionsProject from '@src/hooks/useSessionsProject';
import withSuspense from '@src/components/withSuspense';

type NavigationHeader =
  | 'home'
  | 'projects'
  | 'insights-hub'
  | 'templates'
  | 'sessions';

const TopNavigationContext = withSuspense(({children}) => {
  const pathName = usePathname();
  const searchParams = useSearchParams();
  const params = useParams();
  const projectUid = params.projectUid ? String(params.projectUid) : undefined;
  const {projectData} = useProjectLayoutData(projectUid);

  const isTemplate = searchParams.get('isTemplate')
    ? Boolean(searchParams.get('isTemplate'))
    : projectData?.attributes?.isTemplate;

  const isSession = projectData?.attributes?.isSessionsProject;

  const activeTab: NavigationHeader = useMemo(() => {
    if (pathName == '/') {
      return 'home';
    }
    if (pathName.startsWith('/templates') || (projectUid && isTemplate)) {
      return 'templates';
    }
    if (projectUid && isSession) {
      return 'sessions';
    }
    if (pathName.startsWith('/insights-hub')) {
      return 'insights-hub';
    }
    if (pathName.startsWith('/project')) {
      return 'projects';
    }
  }, [pathName, isTemplate, isSession, projectUid]);

  return <Tabs value={activeTab ?? false}>{children}</Tabs>;
});

const TopNavigation = () => {
  const canManageTemplates = usePermission('manage', 'templates');
  const {goToSessionProject} = useSessionsProject();

  return (
    <TopNavigationContext>
      <Tab component={Link} href="/" label="Home" value="home" />
      <Tab component={Link} href="/projects" label="Projects" value="projects" />
      <Tab label="Sessions" value="sessions" onClick={goToSessionProject} />
      <Tab
        component={Link}
        href="/insights-hub"
        label="Insights Hub"
        value="insights-hub"
      />
      {canManageTemplates && (
        <Tab
          component={Link}
          href="/templates"
          label="Templates"
          value="templates"
        />
      )}
    </TopNavigationContext>
  );
};

export default TopNavigation;
