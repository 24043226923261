'use client';

import {ReactNode, useEffect} from 'react';

import {createMongoAbility} from '@casl/ability';
import {useRouter} from 'next/navigation';

import LoadingView from '../layouts/core/app/LoadingView';
import TetraDots from '../TetraDots';
import SessionContext from '@src/contexts/session';
import {AppAbilities} from '@src/library/permissions';
import {Session} from '@src/library/workos/get-workos-session';

interface Props {
  children: ReactNode;
  session: Session;
}

export default function SessionProvider({children, session}: Props) {
  const router = useRouter();
  useEffect(() => {
    if (!session) {
      router.push(`/api/workos/login?returnTo=${window.location.href}`);
    }
  }, [session]);

  if (!session) {
    return <LoadingView spinner={<TetraDots height={64} spin width={64} />} />;
  }

  return (
    <SessionContext.Provider
      value={{
        organization: session.organization,
        permissions: createMongoAbility<AppAbilities>(session.permissions),
        user: session.user,
        subdomain: session.subdomain,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
}
