import {ReactNode} from 'react';

import {Typography} from '@mui/material';

type Props = {
  children: ReactNode;
};

export default function GroupTitle(props: Props) {
  return (
    <Typography
      sx={{
        px: 1.5,
      }}
      variant="overline"
      {...props}
    />
  );
}
