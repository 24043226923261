import {Box, BoxProps} from '@mui/material';

export default function Item(props: BoxProps<'li'>) {
  return (
    <Box
      component="li"
      sx={(theme) => {
        return {
          py: 1,
          px: 1.5,
          fontSize: '14px',
          listStyleType: 'none',
          cursor: 'pointer',
          '&.Mui-focused': {
            background: theme.colors.gray['100'],
          },
        };
      }}
      {...props}
    />
  );
}
