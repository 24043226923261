import {Box, Typography, TypographyProps} from '@mui/material';

interface Props extends TypographyProps {
  icon?: JSX.Element;
}

export default function ItemLabel({icon, ...typographyProps}: Props) {
  return (
    <Box alignItems="center" display="inline-flex" gap={1}>
      {icon}
      <Typography
        sx={{
          '> em': {
            fontWeight: '600',
            fontStyle: 'normal',
          },
        }}
        variant="body2"
        {...typographyProps}
      />
    </Box>
  );
}
