import {useState} from 'react';

import {
  Box,
  Button,
  DialogTitle,
  IconButton,
  MenuItem,
  Select,
  Stack,
} from '@mui/material';
import Dialog, {DialogProps} from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import {X} from '@phosphor-icons/react';
import {usePostHog} from 'posthog-js/react';

import useSWR from 'swr';
import useSWRMutation from 'swr/mutation';

import TextField from '@src/components/legacy/TextField';
import Typography from '@src/components/legacy/Typography';
import {useCMSClient} from '@src/hooks/useCMSClient';

const InviteUserModal = (dialogProps: DialogProps) => {
  const {onClose} = dialogProps;

  const posthog = usePostHog();

  const [email, setEmail] = useState<string>('');
  const [role, setRole] = useState<string>('Viewer');

  const isValidEmail = email.includes('@') && email.includes('.');

  const CMSClient = useCMSClient();

  const sendWorkosInvitationMutation = useSWRMutation(
    ['send-invitation'],
    async (_key, {arg}: {arg: {email: string; role: string}}) => {
      await CMSClient.sendInvitation({
        input: {
          email: arg.email,
          role: arg.role.toLowerCase(),
        },
      });
    },
    {
      onSuccess() {
        posthog.capture('Invite user', {auditLog: 'Invited a user'});
      },
    }
  );

  const sendInvitation = () => {
    sendWorkosInvitationMutation.trigger({email, role});
  };

  const {data: customRoles} = useSWR(['roles'], async () => {
    const data = await CMSClient.getOrganizationRoles();

    return data.organizationRoles.data;
  });

  return (
    <Dialog fullWidth maxWidth="sm" {...dialogProps}>
      <DialogTitle variant="subtitle2">Invite user</DialogTitle>
      <Box position="absolute" right={8} top={8}>
        <IconButton
          onClick={(event) => {
            onClose(event, 'backdropClick');
          }}
        >
          <X />
        </IconButton>
      </Box>
      <DialogContent sx={{display: 'flex', flexDirection: 'column'}}>
        <Typography variant="body2">
          Add the email of the user you want to invite to this organization. This
          user will receive an invitation email that expires in 7 days.
        </Typography>
        <Stack flexDirection="row" justifyContent="space-between" marginTop={1}>
          <TextField
            autoFocus
            fullWidth
            placeholder="Enter email…"
            sx={{marginRight: 3}}
            value={email}
            onChange={(event) => {
              setEmail(event.target.value);
            }}
          />
          <Box width={165}>
            <Select
              fullWidth
              size="small"
              value={role}
              onChange={(event) => {
                setRole(event.target.value);
              }}
            >
              <MenuItem value="Admin">Admin</MenuItem>
              <MenuItem value="Creator">Creator</MenuItem>
              <MenuItem value="Viewer">Viewer</MenuItem>
              {customRoles?.map((role) => {
                return (
                  <MenuItem key={role.id} value={role.id}>
                    {role.attributes.name}
                  </MenuItem>
                );
              })}
            </Select>
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          onClick={(event) => {
            return onClose(event, 'backdropClick'); // eslint-disable-line react/destructuring-assignment
          }}
        >
          Cancel
        </Button>
        <Button
          disabled={!isValidEmail || !role}
          variant="contained"
          onClick={async (event) => {
            await sendInvitation();
            onClose(event, 'backdropClick'); // eslint-disable-line react/destructuring-assignment
          }}
        >
          Send Invitation
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InviteUserModal;
