import useSWR from 'swr';
import {useDebouncedCallback} from 'use-debounce';

import {useCMSClient} from '@src/hooks/useCMSClient';

import useOrganizationEventListener from './useOrganizationEventListener';

const useProjectLayoutData = (projectUid: string) => {
  const CMSClient = useCMSClient();

  const {
    data: projectData,
    isLoading,
    mutate,
  } = useSWR(projectUid && ['getProjectSidebar', projectUid], async ([, key]) => {
    const response = await CMSClient.getProjectSidebar({
      filters: {
        uid: {
          eq: key,
        },
      },
    });

    return response?.projects?.data?.[0] ?? null;
  });

  const refresh = useDebouncedCallback(mutate, 1000);
  useOrganizationEventListener(({event}) => {
    if (
      event.type === 'plan-blocks-changed' ||
      (event.type === 'project-changed' && event.data.projectUid == projectUid)
    ) {
      refresh();
    }
  });

  return {
    projectData,
    isLoading,
  };
};

export default useProjectLayoutData;
