import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';

import {X} from '@phosphor-icons/react';
import {useRouter} from 'next/navigation';
import toast from 'react-hot-toast';

import Linear from '@components/core/display/progress/LinearProgress';
import Alert from '@components/legacy/Alert';
import Typography from '@components/legacy/Typography';

import {UploadAlertProps} from './UploadAlert.types';

const UploadAlert = ({
  filename,
  goToLink,
  notification,
  onClose,
  progress,
  severity = 'info',
  type,
}: UploadAlertProps) => {
  const router = useRouter();

  const alertMessage = {
    media: {
      success: {
        title: '',
        message: 'Recording uploaded',
      },
      error: {
        message:
          "We couldn't upload your file. Please try again or contact us at support@tetra.team",
        title: 'Something went wrong',
      },
    },
    file: {
      success: {
        title: '',
        message: 'Supporting file uploaded',
      },
      error: {
        message:
          "We couldn't upload your file. Please try again or contact us at support@tetra.team",
        title: 'Something went wrong',
      },
    },
  };

  return (
    <Alert
      action={
        <>
          {goToLink && (
            <Button
              color="inherit"
              size="small"
              onClick={() => {
                router.push(goToLink);
                toast.dismiss(notification?.id);
              }}
            >
              Go to Interview
            </Button>
          )}
          {notification?.id && (
            <IconButton
              color="inherit"
              size="small"
              onClick={() => {
                toast.dismiss(notification?.id);
                onClose?.();
              }}
            >
              <X />
            </IconButton>
          )}
        </>
      }
      alertTitle={
        severity === 'info' ? filename : alertMessage[type][severity].title
      }
      classnames={
        notification.visible ? 'toastNotificationOpen' : 'toastNotificationClosed'
      }
      severity={severity}
    >
      {progress && (
        <Box className="progressContainer" sx={{overflow: 'hidden'}}>
          <Linear color="primary" label="true" value={progress} />
        </Box>
      )}
      {(severity === 'error' || severity === 'success') && (
        <Typography classnames={severity} variant="body1">
          {alertMessage[type][severity].message}
        </Typography>
      )}
    </Alert>
  );
};

export default UploadAlert;
