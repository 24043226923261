import {ReactNode} from 'react';

import {Box} from '@mui/material';

type Props = {
  children: ReactNode;
};

export default function Items(props: Props) {
  return (
    <Box
      component="ul"
      sx={{
        m: 0,
        p: 0,
      }}
      {...props}
    />
  );
}
