import {Typography, TypographyProps} from '@mui/material';

export default function ItemContent(props: TypographyProps) {
  return (
    <Typography
      sx={(theme) => {
        return {
          mt: 0.5,
          ml: 0.5,
          py: 0.25,
          px: 1,
          borderLeft: `4px solid ${theme.colors.gray['200']}`,
          '> em': {
            fontWeight: '600',
            fontStyle: 'normal',
          },
        };
      }}
      variant="body2"
      {...props}
    />
  );
}
