'use client';

import {useRef, useState} from 'react';

import TetraBotContext, {Asset, TetraBotSidebarAPI} from '@src/contexts/tetra-bot';

export default function TetraBotProvider({children}) {
  const [newThreadAssets, setNewThreadAssets] = useState<Asset[]>([]);
  const ref = useRef<TetraBotSidebarAPI>(null);

  return (
    <TetraBotContext.Provider
      value={{
        apiRef: ref,
        newThreadAssets,
        setNewThreadAssets,
      }}
    >
      {children}
    </TetraBotContext.Provider>
  );
}
