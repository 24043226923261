import {createContext} from 'react';

import Uppy, {Restrictions, UppyFile} from '@uppy/core';

export type Meta = {
  attachmentId: string;
  remotePath: string;
  error?: boolean;
  notificationDismissed?: boolean;
};

export interface UppyParams {
  uppyId: string;
  type: 'media' | 'file';
  restrictions?: Restrictions;
  onUploadSuccess?: (file: UppyFile<Meta>) => void;
  onUploadError?: (file: UppyFile<Meta>) => void;
  onBeforeUpload?: (file: UppyFile<Meta>) => void;
}

interface UppyContextValue {
  getUppyInstance?: (params: UppyParams) => Uppy;
}

const UppyContext = createContext<UppyContextValue>({});

export default UppyContext;
