import {Suspense, ComponentType, ReactNode} from 'react';

function withSuspense<Props extends {children?: ReactNode}>(
  Component: ComponentType<Props>
) {
  return function WithSuspenseWrapper({...props}: Props) {
    return (
      <Suspense fallback={props.children}>
        <Component {...props} />
      </Suspense>
    );
  };
}

export default withSuspense;
