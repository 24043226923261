import {Box, BoxProps} from '@mui/material';
import {X} from '@phosphor-icons/react';

type Props = BoxProps<'button'> & {
  isVisible: boolean;
};

export default function ClearButton({isVisible, ...props}: Props) {
  return (
    <Box
      component="button"
      data-visible={isVisible ? true : undefined}
      sx={{
        p: 0,
        opacity: 0,
        background: 'transparent',
        border: 'none',
        appearance: 'none',
        cursor: 'pointer',
        '&[data-visible]': {
          opacity: 1,
        },
      }}
      {...props}
    >
      <X />
    </Box>
  );
}
