import {MutableRefObject, createContext} from 'react';

export interface Asset {
  id?: string | number;
  attributes?: {
    title?: string;
    uid?: string;
    transcript?: {
      data?: {
        attributes?: {
          transcriberUid?: string;
          isReady?: boolean;
        };
      };
    };
  };
}

export type TetraBotSidebarAPI = {
  open: () => void;
  setActiveTab: (key: string) => void;
};

interface TetraBotContextValue {
  apiRef: MutableRefObject<TetraBotSidebarAPI>;
  newThreadAssets: Asset[];
  setNewThreadAssets?: (assets: Asset[]) => void;
}

const TetraBotContext = createContext<TetraBotContextValue>({
  apiRef: null,
  newThreadAssets: [],
});

export default TetraBotContext;
