import {InputBase, InputBaseProps} from '@mui/material';
import clsx from 'clsx';

import css from './TextField.module.css';

export type TextFieldProps = InputBaseProps & {
  classnames?: Array<string> | string;
};

export default function TextField({classnames, ...props}: TextFieldProps) {
  return <InputBase {...props} className={clsx([css['textfield'], classnames])} />;
}
