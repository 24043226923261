import {forwardRef} from 'react';

import {Box, BoxProps} from '@mui/material';

type Props = BoxProps<'ul'> & {
  isOpen: boolean;
};

const SearchResults = forwardRef<HTMLUListElement, Props>(
  ({isOpen, ...props}, ref) => {
    return (
      <Box
        ref={ref}
        component="ul"
        data-open={isOpen ? true : undefined}
        sx={(theme) => {
          return {
            position: 'absolute',
            zIndex: 1300,
            width: 'var(--popover-width)',
            maxHeight: 'min(640px, 80vh)',
            m: '4px 0 0',
            p: 0,
            opacity: 0,
            overflowY: 'auto',
            background: 'white',
            border: '1px solid',
            borderColor: theme.colors.gray['200'],
            borderRadius: '6px',
            boxShadow:
              '0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)',
            transform:
              'translateX(calc(var(--input-width) / 2 - var(--popover-width) / 2))',
            '&[data-open]': {
              opacity: 1,
            },
          };
        }}
        {...props}
      />
    );
  }
);

SearchResults.displayName = 'SearchResults';

export default SearchResults;
