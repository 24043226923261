import {ReactNode} from 'react';

import {Box} from '@mui/material';

type Props = {
  children: ReactNode;
};

export default function Group(props: Props) {
  return (
    <Box
      component="li"
      sx={{
        pt: 2,
        listStyleType: 'none',
        '&:first-of-type': {
          pt: 0.5,
        },
      }}
      {...props}
    />
  );
}
