import Box from '@mui/material/Box';
import LinearProgress, {
  LinearProgressProps,
  linearProgressClasses,
} from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';

interface LinearProps extends LinearProgressProps {
  label?: string;
}

const Linear = (props: LinearProps) => {
  const {color, label = '', value} = props;

  return (
    <Box alignItems="center" display="flex" width="100%">
      <Box mr={1} width="100%">
        <LinearProgress
          sx={{
            [`& .${linearProgressClasses.bar}`]: {
              backgroundColor: color === 'primary' ? '#3E5FE4' : '#5d6b8e',
            },
            [`&.${linearProgressClasses.colorPrimary}`]: {
              backgroundColor: color === 'primary' ? '#D6DEEA' : '#BEC4D2',
            },
            height: 4,
            borderRadius: '4px',
          }}
          variant="determinate"
          {...props}
        />
      </Box>
      {label && (
        <Box className="linearLabelWrapper" minWidth={35}>
          <Typography
            className="label"
            sx={{
              fontSize: '0.875rem',
              color: '#5d6b8e',
            }}
          >
            {`${Math.round(value ?? 0)}%`}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default Linear;
