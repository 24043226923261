import {useContext} from 'react';

import SessionContext from '@src/contexts/session';
import {AppAbilities} from '@src/library/permissions';

const usePermission: AppAbilities['can'] = (...args) => {
  const {permissions} = useContext(SessionContext);
  return permissions?.can(...args);
};

export default usePermission;
