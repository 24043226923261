'use client';

import {useEffect} from 'react';

import {usePathname, useSearchParams} from 'next/navigation';
import {usePostHog} from 'posthog-js/react';

import useUser from '@src/hooks/useUser';
import withSuspense from './withSuspense';

export default withSuspense(() => {
  const pathname = usePathname();
  const posthog = usePostHog();
  const searchParams = useSearchParams();
  const {user} = useUser();

  // Track pageviews
  useEffect(() => {
    if (pathname && posthog) {
      let url = window.origin + pathname;
      if (searchParams.toString()) {
        url = url + `?${searchParams.toString()}`;
      }
      posthog.capture('$pageview', {
        $current_url: url,
        auditLog: 'Viewed page',
      });
    }
  }, [pathname, searchParams, posthog]);

  useEffect(() => {
    if (user.sub) {
      posthog?.identify(user.sub, {
        email: user.email,
      });

      posthog?.group('org', user.org_id, {
        organizationId: user.org_id,
        name: user.org_name,
      });

      posthog?.group('app', 'webApp', {webApp: true});
    }
  }, [user.sub]);

  return null;
});
