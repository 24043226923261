'use client';

import {ReactNode} from 'react';

import AwsS3 from '@uppy/aws-s3';

import Box from '@uppy/box';
import Uppy, {UppyFile} from '@uppy/core';
import Dropbox from '@uppy/dropbox';
import GoogleDrive from '@uppy/google-drive';
import OneDrive from '@uppy/onedrive';
import ScreenCapture from '@uppy/screen-capture';
import Webcam from '@uppy/webcam';
import Zoom from '@uppy/zoom';

import toast from 'react-hot-toast';

import UploadAlert from '../alerts/UploadAlert';
import Alert from '@src/components/legacy/Alert';
import UppyContext, {Meta, UppyParams} from '@src/contexts/uppy';
import {useCMSClient} from '@src/hooks/useCMSClient';
import {useSubdomain} from '@src/hooks/useSubdomain';

type Props = {
  children: ReactNode;
};

// https://github.com/transloadit/uppy/blob/de987014a7af7ab4a3bd7865a6307d7b4724e0a7/packages/%40uppy/core/src/Uppy.js#L1354
const info: Uppy['info'] = (message, type, duration) => {
  toast.custom(
    (notification) => {
      return (
        <Alert
          classnames={
            notification.visible
              ? 'toastNotificationOpen'
              : 'toastNotificationClosed'
          }
          severity={type}
        >
          {typeof message == 'string' ? message : message?.message ?? ''}
        </Alert>
      );
    },
    {
      duration,
    }
  );
};

const uppyInstances = new Map<string, Uppy>();
const uppyParams = new Map<string, UppyParams>();

export default function UppyProvider({children}: Props) {
  const CMSClient = useCMSClient();
  const subdomain = useSubdomain();

  const createUppy = (uppyId: string) => {
    const uppy = new Uppy<Meta>({autoProceed: true, id: uppyId})
      .use(AwsS3, {
        getUploadParameters: async (file: UppyFile<Meta>) => {
          const {onBeforeUpload, type} = uppyParams.get(uppyId);

          const attachment = await CMSClient.createAttachment({
            data: {
              name: file.name,
              type: type,
              publishedAt: new Date(),
            },
          });

          const media = attachment?.createAttachment?.data;

          const {
            ext,
            hash,
            url: signedUrl,
          } = media?.attributes?.attachment?.data?.attributes ?? {};

          uppy.setFileMeta(file.id, {
            attachmentId: media?.id,
            remotePath: `${hash}/${hash}${ext}`,
          });

          onBeforeUpload?.(file);

          return {
            method: 'PUT',
            url: signedUrl,
          };
        },
      })
      .use(GoogleDrive, {
        companionUrl: process.env.COMPANION_BASE_URL,
      })
      .use(OneDrive, {
        companionUrl: process.env.COMPANION_BASE_URL,
      });

    if (subdomain === 'meta') {
      uppy
        .use(Dropbox, {
          companionUrl: process.env.COMPANION_BASE_URL,
        })
        .use(Box, {
          companionUrl: process.env.COMPANION_BASE_URL,
        });
    }

    uppy
      .use(Zoom, {
        companionUrl: process.env.COMPANION_BASE_URL,
      })
      .use(ScreenCapture)
      .use(Webcam, {modes: ['video-audio']});

    // Overwrite info to use react hot toast instead of Informer UI plugin
    uppy.info = info;

    uppy.on('upload-progress', (file, progress) => {
      const {type} = uppyParams.get(uppyId);

      const percentage = (progress.bytesUploaded / progress.bytesTotal) * 100;

      const meta = uppy.getFile(file.id)?.meta;
      if (meta?.notificationDismissed) {
        return;
      }

      toast.custom(
        (notification) => {
          return (
            <UploadAlert
              filename={file.name}
              notification={notification}
              progress={percentage}
              type={type}
              onClose={() => {
                uppy.setFileMeta(file.id, {...meta, notificationDismissed: true});
              }}
            />
          );
        },
        {
          id: file.id,
          duration: Number.POSITIVE_INFINITY,
        }
      );
    });

    uppy.on('upload-success', async (file) => {
      const {onUploadSuccess, type} = uppyParams.get(uppyId);

      toast.custom(
        (notification) => {
          return (
            <UploadAlert
              notification={notification}
              severity="success"
              type={type}
            />
          );
        },
        {
          id: file.id,
          duration: 10_000,
        }
      );

      onUploadSuccess?.(file);
    });

    uppy.on('upload-error', async (file) => {
      toast.custom(
        (notification) => {
          return (
            <UploadAlert notification={notification} severity="error" type="media" />
          );
        },
        {
          id: file.id,
          duration: 10_000,
        }
      );

      const meta = uppy.getFile(file.id)?.meta;
      uppy.setFileMeta(file.id, {...meta, error: true});

      await CMSClient.deleteAttachments({id: meta.attachmentId});

      const {onUploadError} = uppyParams.get(uppyId);
      onUploadError?.(file);
    });

    return uppy;
  };

  const getUppyInstance = (params: UppyParams) => {
    const uppyId = params.uppyId;
    if (!uppyId || typeof window === 'undefined') {
      return;
    }

    uppyParams.set(uppyId, params);

    if (uppyInstances.has(uppyId)) {
      const uppy = uppyInstances.get(uppyId);
      uppy.setOptions({restrictions: params.restrictions});
      return uppy;
    }

    const uppy = createUppy(uppyId);
    uppy.setOptions({restrictions: params.restrictions});
    uppyInstances.set(uppyId, uppy);
    return uppy;
  };

  return (
    <UppyContext.Provider value={{getUppyInstance}}>{children}</UppyContext.Provider>
  );
}
