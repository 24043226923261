import {SVGProps} from 'react';

import '@styles/tetra-dots.css';

interface Props extends SVGProps<SVGSVGElement> {
  spin?: boolean;
  duration?: string;
}

export default function TetraDots({duration = '2s', spin, ...svgProps}: Props) {
  return (
    <svg
      fill="none"
      height={28}
      viewBox="0 0 26 28"
      width={26}
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <g
        className={spin ? 'spin-container' : ''}
        style={{animationDuration: duration}}
      >
        <circle
          className={spin ? 'circle-1' : ''}
          cx={21.79}
          cy={14.05}
          fill="#04D98B"
          r={2.674}
          style={{animationDuration: duration}}
        />
        <circle
          className={spin ? 'circle-2' : ''}
          cx={15.813}
          cy={10.594}
          fill="#19A1F5"
          r={2.674}
          style={{animationDuration: duration}}
        />
        <circle
          className={spin ? 'circle-3' : ''}
          cx={15.813}
          cy={17.515}
          fill="#19A1F5"
          r={2.674}
          style={{animationDuration: duration}}
        />
        <circle
          className={spin ? 'circle-4' : ''}
          cx={10.151}
          cy={7.134}
          fill="#6B85E5"
          r={2.674}
          style={{animationDuration: duration}}
        />
        <circle
          className={spin ? 'circle-5' : ''}
          cx={10.151}
          cy={14.055}
          fill="#6B85E5"
          r={2.674}
          style={{animationDuration: duration}}
        />
        <circle
          className={spin ? 'circle-6' : ''}
          cx={10.151}
          cy={20.975}
          fill="#6B85E5"
          r={2.674}
          style={{animationDuration: duration}}
        />
        <circle
          className={spin ? 'circle-7' : ''}
          cx={4.174}
          cy={3.674}
          fill="#2850E2"
          r={2.674}
          style={{animationDuration: duration}}
        />
        <circle
          className={spin ? 'circle-8' : ''}
          cx={4.174}
          cy={10.594}
          fill="#2850E2"
          r={2.674}
          style={{animationDuration: duration}}
        />
        <circle
          className={spin ? 'circle-9' : ''}
          cx={4.174}
          cy={17.515}
          fill="#2850E2"
          r={2.674}
          style={{animationDuration: duration}}
        />
        <circle
          className={spin ? 'circle-10' : ''}
          cx={4.174}
          cy={24.435}
          fill="#2850E2"
          r={2.674}
          style={{animationDuration: duration}}
        />
      </g>
    </svg>
  );
}
